canvas {
    width: 90vw;
    height : 80vh;
    padding: 0;
    margin: auto;
    margin-bottom: 25px;
    display: block;
    border: 1px solid grey;
    background-color: white;
}

.flex_container {
    display: flex;
    justify-content: space-evenly;
    
  }
  
.top {
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: space-evenly;
    }
    
  
.App {
    text-align: center;
    min-width: 400px;
    min-height: 400px;
    background-color: white;
    
  }